<template>
  <v-dialog v-model="isOpen" max-width="500">
    <v-card>
      <v-card-title>
        <span>{{ $t('General.ConfirmDeletion') }}</span>
      </v-card-title>
      <v-card-text>
        {{ $t('General.AreYouSureDelete') }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeModal">{{ $t('General.Cancel') }}</v-btn>
        <v-btn color="red" text @click="confirmDelete">{{ $t('General.Confirm') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeleteModal',
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    openModal () {
      this.isOpen = true
    },
    closeModal () {
      this.isOpen = false
    },
    confirmDelete () {
      this.$emit('confirm')
      this.closeModal()
    }
  }
}
</script>

<style scoped>
</style>
